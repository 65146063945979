import React from "react";
import qs from "query-string";
import { navigate } from "gatsby";
import Layout from "../components/general/Layout";

// TODO GET EXPERIENCE GET PARAM INSTEAD OF HARD CODED MAP
// PROBABLY FOLLOW THIS LINK -> https://medium.com/@chrisfitkin/how-to-get-query-string-parameter-values-in-gatsby-f714161104f
//

export default ({ location }) => {
    const queryObj = qs.parse(location.search);
    if (queryObj && queryObj.id && queryObj.id.length > 0) {
        const url = `/iframe/ar/index.html?experience=${queryObj.id}`;
        return (
            <Layout>
                <iframe
                    style={{
                        width: "100%",
                        left: "0",
                        height: "100%",
                        position: "fixed",
                        paddingBottom: "110px"
                    }}
                    rameborder="no"
                    title="testing"
                    src={url}
                />
            </Layout>
        );
    }
    /* Hack to let gatsby build without causing its compile time SSR to error
     * see this https://www.gatsbyjs.org/docs/debugging-html-builds/#how-to-check-if-window-is-defined
     */
    if (typeof window !== `undefined`) {
        navigate("/error");
    }
    return null;
};
